<template>
  <div class="recharge common-bg">
    <van-nav-bar title="充值中心" left-arrow @click-left="onClickLeft" />
    <div class="content-box">
      <div class="font-size-18 text-black font-weight">充值金额</div>
      <van-field v-model="money" placeholder="请输入充值金额 (最低充值一百) " />
      <div class="padded-t-15 padded-b-15">
     <!--  <div class="font-size-16 text-black font-weight">充值方式</div>  -->

        <div class="pay-type">
          <van-radio-group v-model="payType">
            <van-radio name="unionpay">银行卡转账</van-radio>
       <!--     <van-radio name="alipay">支付宝支付</van-radio>
            <van-radio name="weixin">微信支付</van-radio>
             <van-radio name="ysf">云闪付</van-radio>  -->
          </van-radio-group>
        </div>
      </div>
      <div class="padded-t-15 padded-b-15">
        <div class="font-size-16 text-black font-weight">转账收款信息</div>
      </div>
      <div class="padded-b-15" v-if="payType === 'unionpay'">
        <div class="set-li">
          <div class="set-li-l font-size-14">收款户名</div>
          <div class="set-li-r">
            <div class="set-txt2">{{ config.bankUsername }}</div>
            <div
              class="set-li-img"
              @click="handleClipboard(config.bankUsername, $event)"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAEnElEQVRYR92XTUxUVxTH/4+PBD86unCoVtpEgiCWaFtXdQGirdHE1IhQowu/ikKsjalG46I17c6olRrFRIEWu1JEo3GjVRlMJCQaTVwYUMyY6EIqLpiBhDgMM+Z9XN699537PphZ9a3I4757f/M/5/zPuVpq/H0aGT2a/TX7M5UGuNeZbK/9LwET4wlEIt0YGnoHTaOk8hsUDXl5eVi+/Gt8WlRECk0rqO+vCNHY2BjWfbceke57mURO+DYUCuHa1StYUVXpOFhLJd6ng+TL5c4r2LR5S9bg2EZVVZWI3LnN7WsqFEzBNHC6uRn7ft6P3NxcPHn8CDNnzpBg9Y2pENMhOXT4MDoud6K4uBgvnvcDaetbK3UyAhwafIPZs2f5VJMG3Flfj/YL/9iAbDfGGbSKuyIRXLzUgZycHJw8cQLTp08LoKBT2Z31uyzABXjxrN/xY80cZE+WvIuWlA89OygNGtBemwUfNFLZI8xq23EC2vDKIonFYjhw8BBu3ryF8WTSZ46plxWGwzjy6y+oq93oWCQCPiNCTLS6hsY9aGlryxiM30A35McPH6Ci4nPBSoQiMXJQNGEyxGWLKzAwMIDy8nKs/vYbpFnpTwE5kUjg3PkWY4+zZ06jsWG3B6CYMSRgSdkiRKMvsWP7NrS1nHdiuXQaeXEsHkf443lIJpM41XQSP+390VtBfv5IjSccGVxSVmYAbt+2FX+1tlg/iRmT5MNCL05bHm2fMByLoXAuBWiuEUPcR7Q6X4BSB+J/khfgcAyF8xhgk6SgB6BmtDpJQQ0oKZUVdEs+1TRjvh8OBEgZtW9A2WiZjAoPtF4bgJMhthTUVTc+141aanX8tvrcS7U6ViR2DnooyDNKBUQCcts5bUY8KyCgalJR/wAzxJ9wVbxXWEwCilWs92LxYFHBVmtDVUjdRysxB3WbYYCm1A5AKWMUCpYjGo1aNsMALU455Tym+0CA+jwoPdkFFPu8jyqWppnnZC+mjFrvJLKCcvvwqGJLCXebIQClYwIWCdOfmu3oQhE7iZdRq3xQyquSUpWCPKDUXRSFnAWbSThudYEBJ+GcYffqJD/s2o2/29vNOwk58uudxJeClES6Q0mVYTDa70QfNEMcj8dx5LffjU//vX0HfX19KC1diP6nT6kq9lskWQD80wR8MziI+UWfCRs2NjTgbPMZ+9rJXM0YFqasoFsLNP9Hhfi/t2+x5Isvjf/n5+dj7do1+OP4cYRCH9lXaqsOtVSSUJAvkjbLqFWGTBk3905ZJD5nD2/AVqmTOKyecGduzZQBJy/uSdaL7V3pKlaE06P1ufqgUWDuvVJLBQGUxyq+YA1+60DObVx90BcgOfKbRv19XS2OHT1qS2cAUpSs5DhBLMjR0VEs/WoZJiYmcKrJ2UmEQYoJKo5bziKpXFGN+z093iUacEVnxyXU1Gywv6Juh1LEnXcSAHe7ulBTW4eRkZGACOrlq1atxI3r11BQUOBjTzs3SUB9h1evX6O3t9eYhDN5dEHC4TlYWV1teJ6/xwegciM5T8iBWn6puFi5HmKmu62gI/fdR3lPJfxO3h7sHwCUPU0yQ1SIbgAAAABJRU5ErkJggg=="
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="set-li">
          <div class="set-li-l font-size-14">收款账号</div>
          <div class="set-li-r">
            <div class="set-txt2">{{ config.bankCard }}</div>
            <div
              class="set-li-img"
              @click="handleClipboard(config.bankCard, $event)"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAEnElEQVRYR92XTUxUVxTH/4+PBD86unCoVtpEgiCWaFtXdQGirdHE1IhQowu/ikKsjalG46I17c6olRrFRIEWu1JEo3GjVRlMJCQaTVwYUMyY6EIqLpiBhDgMM+Z9XN699537PphZ9a3I4757f/M/5/zPuVpq/H0aGT2a/TX7M5UGuNeZbK/9LwET4wlEIt0YGnoHTaOk8hsUDXl5eVi+/Gt8WlRECk0rqO+vCNHY2BjWfbceke57mURO+DYUCuHa1StYUVXpOFhLJd6ng+TL5c4r2LR5S9bg2EZVVZWI3LnN7WsqFEzBNHC6uRn7ft6P3NxcPHn8CDNnzpBg9Y2pENMhOXT4MDoud6K4uBgvnvcDaetbK3UyAhwafIPZs2f5VJMG3Flfj/YL/9iAbDfGGbSKuyIRXLzUgZycHJw8cQLTp08LoKBT2Z31uyzABXjxrN/xY80cZE+WvIuWlA89OygNGtBemwUfNFLZI8xq23EC2vDKIonFYjhw8BBu3ryF8WTSZ46plxWGwzjy6y+oq93oWCQCPiNCTLS6hsY9aGlryxiM30A35McPH6Ci4nPBSoQiMXJQNGEyxGWLKzAwMIDy8nKs/vYbpFnpTwE5kUjg3PkWY4+zZ06jsWG3B6CYMSRgSdkiRKMvsWP7NrS1nHdiuXQaeXEsHkf443lIJpM41XQSP+390VtBfv5IjSccGVxSVmYAbt+2FX+1tlg/iRmT5MNCL05bHm2fMByLoXAuBWiuEUPcR7Q6X4BSB+J/khfgcAyF8xhgk6SgB6BmtDpJQQ0oKZUVdEs+1TRjvh8OBEgZtW9A2WiZjAoPtF4bgJMhthTUVTc+141aanX8tvrcS7U6ViR2DnooyDNKBUQCcts5bUY8KyCgalJR/wAzxJ9wVbxXWEwCilWs92LxYFHBVmtDVUjdRysxB3WbYYCm1A5AKWMUCpYjGo1aNsMALU455Tym+0CA+jwoPdkFFPu8jyqWppnnZC+mjFrvJLKCcvvwqGJLCXebIQClYwIWCdOfmu3oQhE7iZdRq3xQyquSUpWCPKDUXRSFnAWbSThudYEBJ+GcYffqJD/s2o2/29vNOwk58uudxJeClES6Q0mVYTDa70QfNEMcj8dx5LffjU//vX0HfX19KC1diP6nT6kq9lskWQD80wR8MziI+UWfCRs2NjTgbPMZ+9rJXM0YFqasoFsLNP9Hhfi/t2+x5Isvjf/n5+dj7do1+OP4cYRCH9lXaqsOtVSSUJAvkjbLqFWGTBk3905ZJD5nD2/AVqmTOKyecGduzZQBJy/uSdaL7V3pKlaE06P1ufqgUWDuvVJLBQGUxyq+YA1+60DObVx90BcgOfKbRv19XS2OHT1qS2cAUpSs5DhBLMjR0VEs/WoZJiYmcKrJ2UmEQYoJKo5bziKpXFGN+z093iUacEVnxyXU1Gywv6Juh1LEnXcSAHe7ulBTW4eRkZGACOrlq1atxI3r11BQUOBjTzs3SUB9h1evX6O3t9eYhDN5dEHC4TlYWV1teJ6/xwegciM5T8iBWn6puFi5HmKmu62gI/fdR3lPJfxO3h7sHwCUPU0yQ1SIbgAAAABJRU5ErkJggg=="
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="set-li">
          <div class="set-li-l font-size-14">收款银行</div>
          <div class="set-li-r">
            <div class="set-txt2">{{ config.bank }}</div>
            <div
              class="set-li-img"
              @click="handleClipboard(config.bank, $event)"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAEnElEQVRYR92XTUxUVxTH/4+PBD86unCoVtpEgiCWaFtXdQGirdHE1IhQowu/ikKsjalG46I17c6olRrFRIEWu1JEo3GjVRlMJCQaTVwYUMyY6EIqLpiBhDgMM+Z9XN699537PphZ9a3I4757f/M/5/zPuVpq/H0aGT2a/TX7M5UGuNeZbK/9LwET4wlEIt0YGnoHTaOk8hsUDXl5eVi+/Gt8WlRECk0rqO+vCNHY2BjWfbceke57mURO+DYUCuHa1StYUVXpOFhLJd6ng+TL5c4r2LR5S9bg2EZVVZWI3LnN7WsqFEzBNHC6uRn7ft6P3NxcPHn8CDNnzpBg9Y2pENMhOXT4MDoud6K4uBgvnvcDaetbK3UyAhwafIPZs2f5VJMG3Flfj/YL/9iAbDfGGbSKuyIRXLzUgZycHJw8cQLTp08LoKBT2Z31uyzABXjxrN/xY80cZE+WvIuWlA89OygNGtBemwUfNFLZI8xq23EC2vDKIonFYjhw8BBu3ryF8WTSZ46plxWGwzjy6y+oq93oWCQCPiNCTLS6hsY9aGlryxiM30A35McPH6Ci4nPBSoQiMXJQNGEyxGWLKzAwMIDy8nKs/vYbpFnpTwE5kUjg3PkWY4+zZ06jsWG3B6CYMSRgSdkiRKMvsWP7NrS1nHdiuXQaeXEsHkf443lIJpM41XQSP+390VtBfv5IjSccGVxSVmYAbt+2FX+1tlg/iRmT5MNCL05bHm2fMByLoXAuBWiuEUPcR7Q6X4BSB+J/khfgcAyF8xhgk6SgB6BmtDpJQQ0oKZUVdEs+1TRjvh8OBEgZtW9A2WiZjAoPtF4bgJMhthTUVTc+141aanX8tvrcS7U6ViR2DnooyDNKBUQCcts5bUY8KyCgalJR/wAzxJ9wVbxXWEwCilWs92LxYFHBVmtDVUjdRysxB3WbYYCm1A5AKWMUCpYjGo1aNsMALU455Tym+0CA+jwoPdkFFPu8jyqWppnnZC+mjFrvJLKCcvvwqGJLCXebIQClYwIWCdOfmu3oQhE7iZdRq3xQyquSUpWCPKDUXRSFnAWbSThudYEBJ+GcYffqJD/s2o2/29vNOwk58uudxJeClES6Q0mVYTDa70QfNEMcj8dx5LffjU//vX0HfX19KC1diP6nT6kq9lskWQD80wR8MziI+UWfCRs2NjTgbPMZ+9rJXM0YFqasoFsLNP9Hhfi/t2+x5Isvjf/n5+dj7do1+OP4cYRCH9lXaqsOtVSSUJAvkjbLqFWGTBk3905ZJD5nD2/AVqmTOKyecGduzZQBJy/uSdaL7V3pKlaE06P1ufqgUWDuvVJLBQGUxyq+YA1+60DObVx90BcgOfKbRv19XS2OHT1qS2cAUpSs5DhBLMjR0VEs/WoZJiYmcKrJ2UmEQYoJKo5bziKpXFGN+z093iUacEVnxyXU1Gywv6Juh1LEnXcSAHe7ulBTW4eRkZGACOrlq1atxI3r11BQUOBjTzs3SUB9h1evX6O3t9eYhDN5dEHC4TlYWV1teJ6/xwegciM5T8iBWn6puFi5HmKmu62gI/fdR3lPJfxO3h7sHwCUPU0yQ1SIbgAAAABJRU5ErkJggg=="
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="padded-b-15 qrcode-box " v-if="payType === 'alipay'">
        <van-image width="300" height="300" :src="payQRCode" />
      </div>
	  <div class="padded-b-15 text-danger " v-if="payType === 'alipay'">
	  		(请保存上方二维码到支付宝扫码付款)
	  </div>
	  <div class="padded-b-15 qrcode-box " v-if="payType === 'weixin'">
	    <van-image width="300" height="300" :src="payQRCode" />
	  </div>
	  <div class="padded-b-15 text-danger " v-if="payType === 'weixin'">
	  		(请保存上方二维码到微信扫码付款)
	  </div>
    <div class="padded-b-15 qrcode-box " v-if="payType === 'ysf'">
      <van-image width="300" height="300" :src="payQRCode" />
    </div>
    <div class="padded-b-15 text-danger " v-if="payType === 'ysf'">
    		(请保存上方二维码到云闪付扫码付款)
    </div>
      <div class="font-size-16 text-black font-weight">上传转账凭证</div>
      <div class="font-size-12 padded-t-5">
        (请核实转账金额和充值金额一致，其他金额无法到账！)
      </div>
      <div class="pay-img">
        <div class="img1">
          <div class="jiahao">
            <van-uploader
              v-model="fileList"
              :before-read="beforeRead"
              :after-read="afterRead"
              multiple
              :max-count="1"
            />
          </div>
          <div class="jiatxt">
            <div><span>转账截图</span></div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 100px; font-size: 12px">
        (正确转账凭证要求有收款人名字，卡号，转账金额以及转账时间)
      </div>
    </div>
    <div style="position: fixed; bottom: 10px; width: 89%; margin: 0 20px">
      <van-button type="warning" block @click="onSubmit"
        >提交充值订单</van-button
      >
    </div>
  </div>
</template>

<script>
import clipboard from '@/utils/clipboard'
import { getPayConfig, upload, recharge12 } from '@/utils/api.js'
export default {
  name: 'recharge',
  data() {
    return {
      list: '',
      config: {},
      money: '',
      img: '',
      fileList: [],
      payType: 'unionpay'
    }
  },
  computed: {
    payQRCode() {
      let img = ''
      let val = this.payType
      if (val === 'alipay') {
        img = this.config.zfbqrcode
      } else if (val === 'weixin') {
        img = this.config.wxqrcdoe
      } else if (val === 'ysf') {
        img = this.config.ysfqrcode
      }
      return img
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      getPayConfig().then((res) => {
        this.config = res.data
      })
    },
    
    beforeRead(file) {
      if (
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png'
      ) {
        this.$toast('请上传jpg或png格式的图片')
        return false
      }
      return true
    },
    afterRead(file) {
      const formData = new FormData()
      formData.append('file', file.file)
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast('上传成功')
          this.img = res.data
        } else {
          this.$toast('上传失败')
        }
      })
    },
    onSubmit() {
      if (this.money === '') {
        this.$toast('请输入充值金额')
        return
      }
      if (this.money < 100) {
        this.$toast('最低充值100元')
        return
      }
      if (this.img === '') {
        this.$toast('请上传付款凭证截图')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '提交中...',
        forbidClick: true
      })
      const param = {
        money: this.money,
        img: this.img,
				paytype:this.payType
      }
      recharge12(param).then((res) => {
        this.$toast.clear()
        if (res.code === 200) {
          this.$dialog
            .alert({
              title: '温馨提示',
              message: res.message,
              theme: 'round-button'
            })
            .then(() => {
              this.onClickLeft()
            })
        } else {
          this.$toast(res.message)
        }
      })
    },
    handleClipboard(text, event) {
      clipboard(text, event)
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.qrcode-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-box {
  margin: 0 20px;
  padding: 20px 15px;
  background: hsla(0, 0%, 100%, 0.7);
  .van-cell {
    background: transparent !important;
    border-bottom: 1px solid;
    padding: 15px 0 2px 1px;
  }
  .content-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 0;
    .set-li-l {
      display: flex;
      align-items: center;
    }
    .set-li-r {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      margin-left: 10px;
    }
    .set-li-img img {
      width: 20px;
      height: 20px;
    }
  }
  .pay-img {
    width: 100%;
    display: flex;
    justify-content: center;
    .img1 {
      width: 150px;
      height: 150px;
      margin: 25px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      border: 1px dashed #ccc;

      .jiatxt {
        position: absolute;
        bottom: 5px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 5px 0;
      }
    }
  }

  .pay-type {
    padding: 10px 0;
    .van-radio {
      margin-bottom: 10px;
    }
  }
}
</style>
